/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

require("typeface-spacegrotesk")
require("typeface-darker-grotesque")
require("typeface-righteous")
require("typeface-ibm-plex-sans")
